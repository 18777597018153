import style from '../../style/layout.module.scss';


const Header = () => {
    return (
        <header className={style.header}>
            <img className={style.logo} src="/images/officemeet_logo.png" alt="OfficeMeet Logo" />
            <p>관리자페이지</p>
            <div className={style.hamburger}><div className={style.bar}></div></div>
        </header>
    );
}

export default Header;