
import React, {useEffect, useState} from 'react';
import style from './style/main.module.scss';
import Layout from "./components/layout/layout";


export default function Main() {


    const [companyList, setCompanyList] = useState([]);


    useEffect(function() {
console.log();
        fetch(`${process.env.REACT_APP_API_PATH}company/list`).then(res => {
            return res.json();
        }).then(data => {
            if ('result' in data && 'list' in data && data.result === 'ok' && data.list.length > 0) {
                setCompanyList(data.list);
            }
        }).catch(rejected => {
            console.log(rejected);
        });
    });

    return (
        <Layout>
            <div className={style.main}>
                {companyList ? companyList.map((company) => (
                    <div key={company.idx}>{company.name}</div>
                )) : <></>}
            </div>
        </Layout>
    );
}
