import Header from "./header";
import style from '../../style/layout.module.scss';

const Layout = ({children}) => {
    return (
        <div>
            <Header />
            <main className={style.container}>{children}</main>
        </div>
    );
}

export default Layout;